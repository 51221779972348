<template>
  <b-modal
    v-model="show"
    title-class="h3 text-white"
    centered
    size="lg"
    title="Add Motive"
    hide-footer
    modal-class="modal-primary"
    @hidden="hide()"
  >
    <validation-observer #default="{ invalid }">
      <b-row>
        <b-col>
          <b-form class="auth-login-form" @submit.prevent="addMotive">
            <b-form-group label="Motive">
              <validation-provider
                #default="{ errors }"
                :rules="`required|max:` + 255"
              >
                <b-form-textarea
                  :disabled="isDisable"
                  v-model="form.description"
                  placeholder="Enter something..."
                  rows="3"
                  max-rows="6"
                  style="height: 100px; max-height: 150px"
                  :class="errors[0] ? 'border-danger' : ''"
                ></b-form-textarea>

                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <b-button
              type="submit"
              variant="primary"
              class="mr-1"
              block
              :disabled="invalid"
            >
              <feather-icon icon="SaveIcon"> </feather-icon>
              SAVE</b-button
            >
          </b-form>
        </b-col>
      </b-row>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
export default {
  name: "ModalAddCiMotive",
  data() {
    return {
      show: true,
      form: {
        description: "",
      },
      isDisable: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    hide() {
      this.$emit("hide");
    },
    async addMotive() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.addCiMotive(this.form);
        this.showSuccessSwal(data.message);
        this.hide();
      } catch (e) {
        this.showErrorSwal(e);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>