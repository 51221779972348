<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="4" offset-md="8" align="right">
          <b-button variant="outline-primary" size="sm" @click="addMotive">
            <feather-icon icon="PlusIcon"> </feather-icon>
            ADD</b-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group label="Motives">
            <v-select
              v-model="motive"
              :options="motives"
              :clearable="false"
              label="description"
              @input="() => (motiveSelected = { ...motive })"
            />
          </b-form-group>
        </b-col>

        <b-col>
          <validation-provider
            :rules="`required|max:` + 255"
            v-slot="{ errors }"
            slim
          >
            <b-form-group label="Motive">
              <b-form-textarea
                :disabled="isDisable"
                v-model="motiveSelected.description"
                placeholder="Enter something..."
                rows="3"
                max-rows="6"
                style="height: 100px; max-height: 150px"
                :class="errors[0] ? 'border-danger' : ''"
              ></b-form-textarea>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          v-if="isDisable && motives.length > 0"
          lg="12"
          class="text-center"
        >
          <b-button variant="warning" @click="isDisable = false" class="mr-1">
            <feather-icon icon="Edit3Icon"> </feather-icon>
            UPDATE</b-button
          >
          <b-button variant="danger" @click="removeMotive">
            <feather-icon icon="TrashIcon"> </feather-icon>
            DELETE</b-button
          >
        </b-col>
        <b-col v-if="!isDisable" lg="12" class="text-center">
          <b-button variant="primary" class="mr-1" @click="updateOfferMessage">
            <feather-icon icon="SaveIcon"> </feather-icon>
            SAVE</b-button
          >

          <!-- <b-button class="mr-1" @click="removeMotive">
            <feather-icon icon="TrashIcon"> </feather-icon>
            REMOVE</b-button
          > -->

          <b-button variant="danger" @click="isDisable = true">
            <feather-icon icon="XIcon"> </feather-icon>
            RETURN</b-button
          >
        </b-col>
      </b-row>
    </b-card>

    <ModalAddMotive v-if="showModalAddMotive" @hide="hide" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service.js";
import ModalAddMotive from "@/views/debt-solution/views/settings/views/message-cost-config/components/AddMotiveModal.vue";

export default {
  name: "CiMotives",
  components: {
    ModalAddMotive,
  },
  data() {
    return {
      motiveSelected: {
        id: null,
        description: "",
      },
      isDisable: true,
      showModalAddMotive: false,
      motive: {
        id: null,
        description: "",
      },
      motives: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  async created() {
    await this.getCiMotives();
  },
  methods: {
    async removeMotive() {
      try {
        const swalConfirm = await this.showConfirmSwal();
        if (swalConfirm.isConfirmed) {
          const response = await CourtInfoService.deleteCiMotive({
            id: this.motiveSelected,
          });
          if (response.status === 200) {
            this.showSuccessSwal();
            await this.getCiMotives();
            this.isDisable = true;
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    async hide() {
      this.showModalAddMotive = false;
      await this.getCiMotives();
    },
    async getCiMotives() {
      try {
        this.addPreloader();
        const { data } = await CourtInfoService.getCiMotives();
        this.motives = data;
        if (this.motives.length > 0) {
          this.motive = this.motives[0];
          this.motiveSelected = { ...this.motive };
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.removePreloader();
      }
    },
    async updateOfferMessage() {
      try {
        const swalConfirm = await this.showConfirmSwal();
        if (swalConfirm.isConfirmed) {
          const { data } = await CourtInfoService.updateCiMotive(
            this.motiveSelected
          );
          this.showSuccessSwal(data.message);
          await this.getCiMotives();
          this.isDisable = true;
        }
      } catch (e) {
        this.showErrorSwal(e.response.data.message);
      }
    },

    async addMotive() {
      this.showModalAddMotive = true;
    },
  },
};
</script>

<style scoped>
</style>
